<template>
  <div style="padding:15px;background:#fff;line-height:1.6;">
    <p>{{ order.statusText }}</p>
    <p>实付金额 &yen; {{ order.payAmount }}</p>
  </div>
  <van-card
    :num="order.productQuantity"
    :price="order.productPrice"
    :desc="order.productSkuText"
    :title="order.productName"
    :thumb="order.productImage"
  >
  </van-card>
  <template v-if="order.bizType === 'TRANSPORT'">
    <van-cell-group title="收件信息">
      <van-cell title="姓名" :value="order.receiverName" />
      <van-cell title="电话" :value="order.receiverPhone" />
      <van-cell title="省" :value="order.receiverProvince" />
      <van-cell title="市" :value="order.receiverCity" />
      <van-cell title="区" :value="order.receiverCounty" />
      <van-cell title="详细地址" :value="order.receiverAddress" />
    </van-cell-group>
  </template>
  <template v-if="order.bizType === 'BOOKING'">
    <van-cell-group title="预约信息">
      <van-cell title="预约日期" :value="order.bookingDate" v-if="order.bookingDate" />
      <van-cell title="剩余凭证" :value="order.credentialTotalQuantity - order.credentialUsedQuantity" />
      <van-cell title="姓名" :value="order.buyerName" />
      <van-cell title="电话" :value="order.buyerPhone" />
    </van-cell-group>
  </template>
  <van-cell-group title="买家留言" v-if="order.buyerMessage !== ''">
    <van-cell :title="order.buyerMessage" />
  </van-cell-group>
  <van-cell-group title="核销记录" v-if="order && order.writeOffs && order.writeOffs.length">
    <div class="listbox" v-for="(item,index) in order.writeOffs" :key="index">
      <div class="headbox">
        <div class="userphone">商家电话：{{item.phone}}</div>
        <div class="time">{{item.createTime}}</div>
      </div>
      <div class="remark">{{item.remark}}</div>
    </div>
  </van-cell-group>
  <van-cell-group title="订单信息">
    <!-- <van-cell title="来自平台" :value="order.fromPlatform ? '是' : '否'" /> -->
    <van-cell title="订单编号" :value="order.orderNumber" />
    <van-cell title="交易编号" :value="order.tradeNumber" />
    <van-cell title="下单时间" :value="order.createTime" />
    <van-cell title="付款方式" :value="order.paymentMethodText" />
    <van-cell title="应付总额" :value="order.payableAmount" />
    <van-cell title="折扣总额" :value="order.discountAmount" />
    <van-cell title="实付总额" :value="order.payAmount" />
    <!--<van-cell title="其他支出" value="查看" is-link :to="'/order/expenditures?order_id=' + order.orderId" />-->
  </van-cell-group>
  <van-cell-group title="退款信息" v-if="order.refundAmount > 0">
    <van-cell title="退款金额" :value="order.refundAmount" />
    <van-cell title="退款原因" :value="order.refundReason" />
    <van-cell title="申请时间" :value="order.refundApplyTime" />
  </van-cell-group>
  <van-cell-group title="评价" v-if="order.comment">
    <van-cell title="类型" :value="order.commentGradeText" />
    <van-cell title="内容" :value="order.commentContent" />
  </van-cell-group>
  <!-- <van-cell-group title="分账记录">
    <template v-for="item in funds" :key="item">
      <van-cell :title="item.user.phone" :label="item.memo" :value="'金额 ' + item.amount" />
    </template>
  </van-cell-group> -->
  <div style="height:70px;"></div>
  <div class="van-hairline--top" style="position:fixed;z-index:1;bottom:0;background:#fff;width:100%;">
    <div style="margin:15px;text-align:right;">
      <template v-if="order.status === 'WAIT_USE'">
        <template v-if="order.bizType === 'BOOKING'">
          &nbsp;<van-button size="small" type="primary" :to="{ name: 'OrderCredential', query: { orderId: order.orderId } }">核销</van-button>
        </template>
        <template v-else-if="order.bizType === 'BOOKING_IOT'">
          &nbsp;<van-button size="small" type="primary" :to="{ name: 'OrderIotStart', query: { orderId: order.orderId } }">启动设备</van-button>
        </template>
        &nbsp;<van-button size="small" type="primary" :to="{ name: 'OrderRefund', query: { orderId: order.orderId } }">退款</van-button>
      </template>
      <template v-if="order.status === 'WAIT_DELIVER'">
        &nbsp;<van-button size="small" type="primary" :to="{ name: 'OrderRefund', query: { orderId: order.orderId } }">退款</van-button>
      </template>
      <template v-if="order.status === 'DELIVERED'">
        &nbsp;<van-button size="small" type="primary" :to="{ name: 'OrderRefund', query: { orderId: order.orderId } }">退款</van-button>
        &nbsp;<van-button size="small" type="primary" :to="{ name: 'Logistics', query: { orderId: order.orderId } }">物流</van-button>
        &nbsp;<van-button size="small" type="primary" :to="{ name: 'OrderReceipt', query: { orderId: order.orderId } }">确认收货</van-button>
      </template>
      <template v-if="order.status === 'COMPLETED' && !order.comment">
        &nbsp;<van-button size="small" type="primary" :to="{ name: 'AddComment', query: { orderId: order.orderId } }">评价</van-button>
      </template>
      <!-- 如果发货时间不为null， 物流按钮 为可见，不然订单完成后，无法查看物流 -->
    </div>
  </div>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import { convertOrderStatusText } from '@/util/util'

export default {
  setup () {
    const post = inject('post')
    const useRoute = inject('useRoute')
    const state = reactive({
      orderId: useRoute.query.orderId,
      seller: {},
      order: {
        productSnapshot: {},
        expenditures: [],
        trade: {
          paymentMethod: {}
        }
      },
      funds: []
    })
    const convertStatus = (status, biz) => {
      return convertOrderStatusText({ status: status, biz: biz })
    }
    const init = () => {
      post('/order.get', {
        orderId: state.orderId
      }).then(res => {
        state.order = res.data
      })
    //   listFund({
    //     orderId: state.orderId
    //   }).then(res => {
    //     state.funds = res.data.content
    //   })
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      convertStatus
    }
  }
}
</script>

<style scoped>
  .listbox{
    background: #FFFFFF;
    position: relative;
    padding:var(--van-cell-vertical-padding) var(--van-cell-horizontal-padding);
  }
  .listbox::after {
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    right: var(--van-padding-md);
    bottom: 0;
    left: var(--van-padding-md);
    border-bottom: 1px solid var(--van-cell-border-color);
    transform: scaleY(.5);
  }
  .listbox .headbox{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
  }
  .listbox .headbox .time{
    color: #999999;
  }
  .listbox .remark{
    margin-top: 8px;
    text-align: justify;
    font-size: 12px;
    color: #999999;
  }
</style>
